import React, { Fragment } from 'react'
import Page from '../../components/page'
import withRoot from '../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  Button,
} from '@material-ui/core'

import { navigate } from 'gatsby'

import Stepper from '../../components/stepper'

import install01 from '../../static/fitface-install/01.jpg'
import install02 from '../../static/fitface-install/02.jpg'
import install03 from '../../static/fitface-install/03.jpg'
import install04 from '../../static/fitface-install/04.jpg'
import install05 from '../../static/fitface-install/05.jpg'

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
})

@withRoot
@withStyles(styles)
export default class GetStarted extends React.Component {
  render() {
    const { classes } = this.props

    const STEPS = [
      { label: 'Create your FitFace account.', content: 'Don\'t hesitate, it is FREE!', img: '' },
      {
        label: (
          <Typography>Install our FitFace clockface from the <a className={classes.link} href="https://gallery.fitbit.com/details/0799c9f7-8aa4-4795-9af2-8f8087654321" target="_blank" rel="noopener noreferrer">Fibit gallery</a> using your mobile phone.</Typography>
        ),
        content: (
          <Typography>With this, you will be able to see your FitFace watch face collection and switch between your favorite designs easily.</Typography>
        ),
        img: install01
      },
      { label: 'Accept the needed permissions', content: 'Make sure to select all permissions or some features will not work properly.', img: install02 },
      { label: 'Open the clockface settings', content: '', img: install03 },
      { label: 'Enter your FitFace credentials and press Login', content: '', img: install04 },
      {
        label: 'Explore the settings page',
        content: (
          <Fragment>
            <Typography>From this page, you will be able to choose the design you want to display on your watch.</Typography>
            <Typography>This page also gives access to the settings of the installed watch face designs (e.g. weather temperature unit and colour themes).</Typography>
          </Fragment>
        ),
        img: install05
      },
      {
        label: 'Your account is now correctly set-up',
        content: (<Typography>Let&apos;s add some watch face designs to your collection now.</Typography>),
        img: ''
      },
    ]

    return (
      <Page toggleTheme={this.props.toggleTheme} title="Get started">
        <Typography className={classes.parag}>
          Welcome to FitFace.xyz!<br />
          Please follow the steps below to make sure you&apos;ve covered the basis to setup your account correctly.
        </Typography>
        <Stepper steps={STEPS} lastStep={<Button variant="contained" color="primary" onClick={() => navigate('/user/collections')}>Go to the next level</Button>} />
      </Page>
    )
  }
}
